import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'


import firebase from "firebase/app"
import "firebase/analytics"
    
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_kn9t-fCxZQGOgdguynIItBdY3jHaRas",
  authDomain: "e2r-tts.firebaseapp.com",
  projectId: "e2r-tts",
  storageBucket: "e2r-tts.appspot.com",
  messagingSenderId: "61077827114",
  appId: "1:61077827114:web:0b020ec9cd27a239f21d53",
  measurementId: "G-PV4X05YRS0"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


const analytics = firebase.analytics;

Vue.config.productionTip = false

Vue.use(VueResource);

// alias
Vue.prototype.$analytics = firebase.analytics();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


analytics().setCurrentScreen(router.currentRoute.path); // sets `screen_name` parameter
analytics().logEvent('screen_view'); // log event with `screen_name` parameter attached

router.afterEach((to) => {
  //automatic page title 
  const DEFAULT_TITLE = 'Easy2Reading';
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  analytics().setCurrentScreen(to.path); // sets `screen_name` parameter
  analytics().logEvent('screen_view'); // log event with `screen_name` parameter attached

})

