<template>
<v-card class="rounded">
<div >
        <v-card height="50vh" v-for="mainitem in data" :key="mainitem.title">
                <v-card-title>{{mainitem.title}}</v-card-title>
                <v-card-text>
                        <v-expansion-panels focusable>
                            <v-expansion-panel
                              v-for="item in mainitem.list"
                              :key="item.heading"
                            >
                              <v-expansion-panel-header>{{item.heading}}</v-expansion-panel-header>
                              <v-expansion-panel-content>{{item.text}}</v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                </v-card-text>
        </v-card>
</div>
</v-card>
</template>
<script>
export default {
        data () {
                return{
                        data:this.$store.state.privacy
                }
        }
    
}
</script>