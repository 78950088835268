<template>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <!--  -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" x-large>
            Easy2Reading
          </v-list-item-title>
          <v-list-item-subtitle>
            Free online text to speech
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in this.$store.state.items"
            :key="item.title"
            link
            router
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
          
      </v-list>
    </v-navigation-drawer>    
</template>
<script>
export default {
    computed: {
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (value) {
          this.$store.commit('updateDrawer', value)
        }
      }
    },
}
</script>