<template>
  <div>    
    <v-container class="col-sm-12">      
      <v-form    ref="form"    v-model="valid" lazy-validation   >
        <v-textarea
          :clearable="!download"
          clear-icon="mdi-close-circle"
          label="Enter text"
          v-model="data.input.text"
          outlined
          :counter="5000"
          :rules="this.$store.state.textrules"
          required
          :rows=" $vuetify.breakpoint.xs ? 5 : 8"
        ></v-textarea>
        <template v-if="!download">
          <v-card  elevation="0">
          <v-card-text>
            <v-row>
              <v-col>              
                <v-select
                v-model="data.voice.languageCode"
                :items="this.$store.state.voices"
                item-text="Supported_language"
                item-value="Supported_language"
                :rules="this.$store.state.speedrules"
                label="Language-Locality:"
                required    
                ></v-select>
                <v-select
                v-model="data.voice.ssmlGender"
                :items="this.$store.state.voices"
                item-text="SSML_Voice_Gender"
                item-value="SSML_Voice_Gender"
                :rules="this.$store.state.speedrules"
                label="Gender:"
                required    
                ></v-select>
                <v-select
                v-model="data.voice.name"
                :items="this.$store.getters.list_of_voices(data.voice.languageCode,data.voice.ssmlGender)"
                item-text="Name"
                item-value="Name"
                :rules="this.$store.state.speedrules"
                label="Voice"
                required    
                ></v-select>
                  <v-select
                v-model="data.audioConfig.speakingRate"
                :items="this.$store.state.speed_modes"
                item-text="mode"
                item-value="value"
                :rules="this.$store.state.speedrules"
                label="Speed"
                required   
              
                v-show="pro"
                ></v-select>
              </v-col>
              <v-col>              
                <v-select
                v-model="data.audioConfig.effectsProfileId"
                :items="this.$store.state.device"
                item-text="name"
                item-value="value"
                :rules="this.$store.state.speedrules"
                label="Device:"
                required   
                v-show="pro"
                ></v-select>
                <v-select
                v-model="data.audioConfig.audioEncoding"
                :items="this.$store.state.audio_format"
                item-text="name"
                item-value="value"
                :rules="this.$store.state.speedrules"
                label="Audio Format:"
                required   
                v-show="pro"
                ></v-select>
                <v-select
                v-model="data.audioConfig.volumeGainDb"
                :items="this.$store.state.volume"
                item-text="name"
                item-value="value"
                :rules="this.$store.state.speedrules"
                label="Volume"
                required  
                v-show="pro"
                ></v-select>
                  <v-select
                v-model="data.audioConfig.pitch"
                :items="this.$store.state.pitch"
                item-text="name"
                item-value="value"
                :rules="this.$store.state.speedrules"
                label="Pitch"
                required  
                v-show="pro"
                ></v-select>
              </v-col>
            </v-row>
          <v-text-field
          v-model="filename"
          :counter="10"
          :rules="this.$store.state.nameRules"
          label="Name of Audio file"
          required
          clearable
          clear-icon="mdi-close-circle"
        ></v-text-field>  
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
               @click="pro=!pro" 
              :color="pro ? 'primary' : 'grey lighten-1'"              
              class="mr-4"
            >
              Pro Version            
            </v-btn>   
           <v-btn
              :disabled="!data.input.text || !filename || !data.voice.name"
              color="primary"
              class="mr-4"
              @click="submit"              
            >
              Submit        
            <v-icon right>mdi-send</v-icon>
            </v-btn>   
          </v-card-actions>
        </v-card>          
        </template>            
      </v-form>      
      <template v-if="download">
        <v-card elevation="0">
            <v-card-text>
              <audio controls type="audio/mpeg" :src="url"></audio>

            </v-card-text>
            <v-card-actions>             
              <v-btn @click="back" color="primary" outlined>back<v-icon>mdi-arrow-left</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="downloads" :href="url" :download="filename" outlined color="primary">Download<v-icon>mdi-download</v-icon></v-btn>

            </v-card-actions>
          </v-card>
      </template>      
    </v-container>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="200"
        align-center
      >
      Generating Audio File
      </v-progress-circular>
    </v-overlay>
  </div>  
</template>
<script>


  export default {  
    data(){
      return{
        valid: false,
        pro: false,
        loading:false,
        download:false,
        response:{url:'https://storage.googleapis.com/easy2reading.com/en_us_female.mp3'},
        url:'',
        filename: 'Audio',
        data:{
          input:{text:''},
          voice:{languageCode:'en-US',name: 'en-US-Standard-C',ssmlGender:'FEMALE'},
          audioConfig:{audioEncoding: 'MP3',speakingRate: 1.00,pitch: 0.0,volumeGainDb: 0.0,effectsProfileId:'handset-class-device'},       

        }
      }
    },
    methods:{
      
      async open_smart_link_ad(){ 
        var smart_link = "http://zikroarg.com/4/3768805";
        window.open(smart_link, "ad"); 
        },
      async bloburl(){
            const Data = await fetch(`data:audio/mpeg;base64,${this.response}`);
            const DataBlob =await Data.blob();
            window.URL =  window.URL || window.webkitURL;
            this.url = window.URL.createObjectURL(DataBlob);
            },
     async submit(){
        this.loading = true; 
        this.open_smart_link_ad();
        await this.$http.post('https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyCO89HxwbEdtgEB0WFqwcu-5kJOhYlWRu0',this.data,{'Content-Type':'application/json; charset=utf-8'})
          .then( response =>{
            this.response=response.data.audioContent;
            this.loading = false;
            this.download=true;
            this.$analytics.logEvent('submit'); 
          })          
          .catch(() => {
            this.loading=false;
            alert('Please Refresh and Try Again');
            this.$analytics.logEvent('submit failed');

          });
       
      await this.bloburl();       
      },    
      async downloads(){    
        this.open_smart_link_ad();   
        this.$analytics.logEvent('downloads');
      },

      back(){
        this.$analytics.logEvent('back');
        this.download=!this.download;
      },     
    },     
  }
</script>